._17tHA {
  color: #457b9d;
  color: #45ab7a;
  color: #f68963;
  color: #e0e0e0;
  color: #828282;
}

._3HGW6 {
  display: grid;
}

._1YyvB {
  position: absolute;
  box-sizing: border-box;
  border: 1px solid #bdbdbd;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 0;
  padding: 20px;
}

._1Mtyx {
  font-weight: bold;
  color: #888888;
  position: relative;
  top: -10px;
  text-align: center;
}

._vVFTB {
  top: 0;
}

._P2Yux {
  position: relative;
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  border: solid 2px #909090;
  background: #bdbdbd;
}
._P2Yux > * {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin: -21px !important;
}
._P2Yux > *:nth-of-type(1) {
  transform: rotate(0deg) translate(1rem) rotate(-90deg);
}
._P2Yux > *:nth-of-type(2) {
  transform: rotate(120deg) translate(1rem) rotate(-90deg);
}
._P2Yux > *:nth-of-type(3) {
  transform: rotate(240deg) translate(1rem) rotate(-90deg);
}

._2Hmaa {
  position: relative;
  width: 6rem;
  height: 6rem;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  border: solid 2px #909090;
  background: #bdbdbd;
}
._2Hmaa > * {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin: -21px !important;
}
._2Hmaa > *:nth-of-type(1) {
  transform: rotate(0deg) translate(3rem) rotate(-90deg);
}
._2Hmaa > *:nth-of-type(2) {
  transform: rotate(72deg) translate(3rem) rotate(-90deg);
}
._2Hmaa > *:nth-of-type(3) {
  transform: rotate(144deg) translate(3rem) rotate(-90deg);
}
._2Hmaa > *:nth-of-type(4) {
  transform: rotate(216deg) translate(3rem) rotate(-90deg);
}
._2Hmaa > *:nth-of-type(5) {
  transform: rotate(288deg) translate(3rem) rotate(-90deg);
}

._3XdV3 {
  position: relative;
  width: 10rem;
  height: 10rem;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  border: solid 2px #909090;
  background: #bdbdbd;
}
._3XdV3 > * {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin: -21px !important;
}
._3XdV3 > *:nth-of-type(1) {
  transform: rotate(0deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(2) {
  transform: rotate(45deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(3) {
  transform: rotate(90deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(4) {
  transform: rotate(135deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(5) {
  transform: rotate(180deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(6) {
  transform: rotate(225deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(7) {
  transform: rotate(270deg) translate(5rem) rotate(-90deg);
}
._3XdV3 > *:nth-of-type(8) {
  transform: rotate(315deg) translate(5rem) rotate(-90deg);
}

._2O2-y {
  display: flex;
}

._2uGW0 {
  border: 2px dashed transparent;
  position: relative;
  width: 42px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 1px;
  transition: all 0.1s ease-in-out;
  border-radius: 18px 18px 3px 3px;
}

._2uGW0._-l1MX {
  visibility: hidden;
}

._2uGW0._39S5s ._OwprV {
  width: 25px;
  height: 25px;
  border-radius: 50px !important;
  cursor: not-allowed;
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: #555555;
  font-weight: bold;
}

._OwprV {
  position: absolute;
  border: 1px solid #254254;
  background-color: #457b9d;
  width: 36px;
  height: 26px;
  border-radius: 20px 20px 5px 5px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
  color: white;
  text-align: center;
}

._2Z_Js {
  background: #f68963;
  border-color: #f68963;
}

._WfFcA {
  border: 2px dashed #f68963;
  border-radius: 22px 22px 6px 6px;
}

._2JCKu {
  background: #45ab7a;
  border-color: #378861;
}

._fRIZ0 {
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: #555555;
  font-weight: bold;
}

._1TWro {
  border: 4px solid #45ab7a;
  border-style: solid;
  background-color: transparent;
  border-radius: 22px 22px 6px 6px;
  height: 70%;
  width: 75%;
}

._lwcf_ {
  position: absolute;
  top: 10px;
  right: 5px;
  display: flex;
  color: #626262;
}

._3wODo {
  border-radius: 10px;
  height: 40px;
  width: 40px;
  border: 1px solid #c4c4c4;
  background: rgba(255, 255, 255, 0.8901960784);
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  padding: 9px;
}

/* .outerSeat.seatSelected + .outerSeat {
  border-right: 1px solid transparent;
  border-radius: 22px 0px 0px 6px;
} */
/* .outerSeat + .outerSeat.seatSelected {
  border-right: 1px solid transparent;
  border-radius: 22px 0px 0px 6px;
}
.seatSelected.outerSeat + .seatSelected.outerSeat {
  border-left: 1px  solid transparent;
  border-radius: 0px 22px 6px 0px;
} */